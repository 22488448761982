import { Box } from '@mui/material';
import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ElevadeLogo from '../../utils/images/ElevadeLogo.svg';
import AerotradeLogo from '../../utils/images/AerotradeLogo.svg';

export const AuthMainLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = window.location.search;
  const queryObject = new URLSearchParams(query);
  const originURL = queryObject.get('origin');
  const isFromAeroTrade = originURL?.includes('aerotrade');

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/login');
    }
  });

  function onLogoClick() {
    //Check if  origin url is defined
    if (originURL && originURL != 'null') {
      window.location.href = originURL;
    } else {
      window.location.href = process.env.REACT_APP_ELEVADE_URL || '';
    }
  }

  return (
    <>
      <div
        style={{ zIndex: 9999, position: 'sticky', cursor: 'pointer' }}
        onClick={onLogoClick}
      >
        {isFromAeroTrade ? (
          <img
            src={AerotradeLogo}
            alt="elevade"
            style={{
              paddingTop: '90px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingBottom: '40px',
            }}
          />
        ) : (
          <img
            src={ElevadeLogo}
            alt="elevade"
            style={{
              paddingTop: '90px',
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              width: '117px',
              height: '20px',
              paddingBottom: '40px',
            }}
          />
        )}
      </div>{' '}
      <div style={{ height: 12 }}></div>
      <div>
        <Box>
          <Outlet />
        </Box>{' '}
      </div>
    </>
  );
};
