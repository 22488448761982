import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { STATUS_CODE } from '../../constant/statusCode';
import CloseIcon from '@mui/icons-material/Close';
import { API_AUTH_GS_V1 } from '../../constant';
import { TextFieldPassword } from '../../components';

const queryStringRegex = new RegExp('^(?=.*\\borigin\\b)(?=.*\\bcontinue\\b).*$');

export const Login = () => {
  // If the screen go smaller than 500px it will return false
  const [openModal, setOpenModal] = useState<boolean>(false);
  const password = useRef<any>(null);
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [googleLoginEnabled, setGoogleLoginEnabled] = useState<boolean>(true);
  const [normalLoginEnabled, setNormalLoginEnabled] = useState<boolean>(true);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const query = window.location.search;
  const queryObject = new URLSearchParams(query);
  const originURL = queryObject.get('origin');

  const normalLogin = queryObject.get('normalLogin');
  const googleLogin = queryObject.get('googleLogin');
  const canSignUp = queryObject.get('newAcc');
  const version = queryObject.get('version');
  const errorMessage = queryObject.get('error');

  // NOTE: by default, we will show the sign up button
  const isSignUpAllowed = canSignUp === 'true' ? true : false;

  useEffect(() => {
    // Trigger the google function
    // Check if queryObject has values and they are false
    if (googleLogin === 'false') {
      setGoogleLoginEnabled(false);
    }

    if (normalLogin === 'false') {
      setNormalLoginEnabled(false);
    }

    if (googleLogin === 'false' && normalLogin === 'false') {
      setGoogleLoginEnabled(true); // You can choose to set the other one to true as well
    }
    try {
      window.initGoogleAuth();
    } catch (e) {
      console.log('Google Authentication Not Supported ');
      setGoogleLoginEnabled(false);
      setNormalLoginEnabled(true);
    }
  }, [googleLogin, normalLogin]);

  const errCodeQuery = queryObject.get('errCode');
  const [errCode, setErrCode] = useState<null | string>(errCodeQuery);
  const typedEmail = queryObject.get('email');
  const email = useRef<any>(typedEmail);

  const [toggleErr, setToggleErr] = useState<boolean>(false);

  useEffect(() => {
    /**
     * Check the query string from the url
     *
     * 1. The query string must have this 2 words "origin" and "continue"
     * 2. Else, will bring to a not found page
     */
    if (!queryStringRegex.test(query)) {
      setOpenModal(false);
    }

    if (errCode) {
      setToggleErr(true);
    }
    if (errorMessage) {
      setToggleErr(true);
    }
  }, [query, errCode, errorMessage]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleClose = () => {
    setOpenBackdrop(false);
  };

  const login = async (evt: any) => {
    evt.preventDefault();

    if (validateInput()) {
      setOpenBackdrop(true);

      try {
        setIsLoading(true);

        const resp = await axios.post(`${API_AUTH_GS_V1}auth/login${query}`, {
          email: email.current,
          password: password.current,
        });

        const data = resp.data;

        setUsernameError('');
        setIsLoading(false);

        // Redirect to the new domain
        window.location.href = `${data.redirectRoute}`;
      } catch (err: any) {
        console.log('🚀 ~ login ~ err:', err);

        let error_message =
          'No account found with this email. Try again or contact your administrator';
        if (err.response?.data) {
          const errData = err.response.data;

          console.log('🚀 ~ login ~ errData:', errData);

          // If the status code match, will redirect
          if (errData?.detail?.status === STATUS_CODE.CONTINUE_URL_NOT_VALID) {
            window.location.replace('/notFound.html');
          } else if (errData?.detail?.status === STATUS_CODE.USER_IS_DEACTIVATED) {
            setToggleErr(true);
            setErrCode(JSON.stringify(errData.status));
            setIsLoading(false);
            setOpenBackdrop(false);
            return;
          } else if (errData?.detail?.msg) {
            error_message = errData.detail.msg;
          }
        }

        setUsernameError(error_message);
        setIsLoading(false);
        setOpenBackdrop(false);
      }
    }
  };

  const validateInput = () => {
    if (!email.current || email.current === '') {
      setUsernameError('Please enter the Username');
      return false;
    }
    if (!password.current || password.current === '') {
      setPasswordError('Please enter the Password');
      return false;
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.current)) {
      setUsernameError('Please insert a valid email address');
      return false;
    }

    setUsernameError('');
    setPasswordError('');
    return true;
  };

  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '1px solid #000',
    borderRadius: 2,
    boxShadow: 5,
    pt: 2,
    px: 4,
    pb: 3,
  };

  const boxStyle = {
    boxShadow: 'none', // This removes the border
    background: 'transparent',
    width: '100%',
    maxWidth: '420px',
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginLeft: '30px',
          marginRight: '30px',
        }}
      >
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={openBackdrop}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        {
          <Modal
            open={openModal}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
          >
            <Box sx={{ ...style, width: 400 }}>
              <h2 id="parent-modal-title">Not Valid</h2>
              <p id="parent-modal-description">
                The request is not valid, please close this browser and try login again.
              </p>

              <Button
                variant="outlined"
                onClick={() => window.location.replace('/notFound.html')}
              >
                OK
              </Button>
            </Box>
          </Modal>
        }

        {/* Error msg */}
        <Collapse in={toggleErr}>
          <Alert
            sx={{ mb: 2, minWidth: 350, maxWidth: 400 }}
            severity="error"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setToggleErr(!toggleErr);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {errorMessage}
          </Alert>
        </Collapse>

        <Paper
          elevation={2}
          sx={{
            padding: 2,
          }}
          style={boxStyle}
          //style={sm === false ? { boxShadow: 'none' } : {}}
        >
          <Typography
            textAlign={'center'}
            fontFamily={'Gotham-Medium'}
            fontSize={24}
            component="p"
          >
            Log In
          </Typography>
          {googleLoginEnabled !== false && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div
                id="google_btn"
                style={{
                  cursor: 'pointer',
                  paddingTop: '35px',
                  paddingBottom: '35px',
                }}
              >
                Google Login
              </div>
            </Box>
          )}

          {googleLoginEnabled !== false && normalLoginEnabled !== false && (
            <Divider
              sx={{
                fontFamily: 'Gotham-Book',
                marginBottom: 2,
              }}
            >
              or
              {/* <Chip label="OR" /> */}
            </Divider>
          )}

          {normalLoginEnabled !== false && (
            <>
              <form onSubmit={login}>
                <Typography sx={{ fontFamily: 'Gotham-book', fontSize: 16, mb: 1 }}>
                  Username
                </Typography>

                <TextField
                  name="email"
                  id="email"
                  variant="outlined"
                  fullWidth
                  error={usernameError ? true : false}
                  helperText={usernameError ? usernameError : ''}
                  sx={{
                    marginBottom: 2,
                    fontFamily: 'Gotham-Book',
                  }}
                  size="medium"
                  defaultValue={typedEmail || ''}
                  inputProps={{
                    style: {
                      fontFamily: 'Gotham-book',
                      fontSize: '15px',
                      borderRadius: '5px',
                      backgroundColor: 'white',
                    },
                    placeholder: 'Email address',
                  }}
                  onChange={(evt) => {
                    email.current = evt.target.value;
                    setUsernameError('');
                  }}
                />
                <Typography sx={{ fontFamily: 'Gotham-book', fontSize: 16, mb: 1 }}>
                  Password
                </Typography>
                <TextFieldPassword
                  name="password"
                  id="password"
                  variant="outlined"
                  fullWidth
                  error={passwordError ? true : false}
                  helperText={passwordError ? passwordError : ''}
                  onChange={(evt) => {
                    password.current = evt.target.value;
                    setPasswordError('');
                  }}
                  inputProps={{
                    style: {
                      fontFamily: 'Gotham-book',
                      fontSize: '15px',
                      borderRadius: '5px',
                      backgroundColor: 'white',
                    },
                    placeholder: 'Enter password',
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: 'Gotham-Book',
                    fontSize: 13,
                    mb: 4,
                    textAlign: 'right',
                    marginTop: '3px',
                    marginBottom: '2px',
                  }}
                >
                  <Link
                    style={{
                      color: 'black',
                      textDecoration: 'none',
                    }}
                    href={`/forgot-password?origin=${originURL}`}
                  >
                    Forgot Password?
                  </Link>
                </Typography>
                {isLoading ? (
                  <Box sx={{ width: '100%', mt: 3, mb: 3 }}>
                    <LinearProgress sx={{ height: 6 }} />
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    sx={{
                      fontFamily: 'Gotham-Medium',
                      fontSize: 15,
                      marginTop: 2,
                      marginBottom: 2,
                      height: 55,
                      backgroundColor: '#088FD1',
                      textTransform: 'none',
                    }}
                  >
                    Log In
                  </Button>
                )}
              </form>
              <Typography
                sx={{
                  fontFamily: 'Gotham-Book',
                  fontSize: 14,
                  mb: 4,
                  textAlign: 'center',
                  marginTop: '0px',
                  marginBottom: '0px',
                }}
              >
                <Link
                  style={{ color: 'black', textDecoration: 'none' }}
                  href={`/first-time-login`}
                >
                  Click here for
                  <span style={{ fontFamily: 'Gotham-Medium', color: '#00A8E8' }}>
                    {' '}
                    First Time Login
                  </span>
                </Link>
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Gotham-Book',
                  color: '#838383',
                  fontSize: 12,
                  mb: 4,
                  textAlign: 'center',
                  marginTop: '52px',
                  marginBottom: '2px',
                }}
              >
                By continuing, you acknowledge that you have read, understood
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Gotham-Book',
                  color: '#838383',
                  fontSize: 12,
                  mb: 4,
                  textAlign: 'center',
                  marginBottom: '2px',
                }}
              >
                agree to ADE's{' '}
                <Link
                  style={{ color: '#00A8E8', textDecoration: 'none' }}
                  href={`https://ade.aero/terms-of-use`}
                  target="_blank"
                >
                  Terms of Use
                </Link>
                &nbsp;and&nbsp;
                <Link
                  style={{ color: '#00A8E8', textDecoration: 'none' }}
                  href={`http://ade.aero/privacy-statement`}
                  target="_blank"
                >
                  Privacy Policy{' '}
                </Link>
              </Typography>
            </>
          )}
        </Paper>

        {isSignUpAllowed && (
          <Typography
            sx={{
              color: '#838383',
              fontSize: 12,
              mb: 4,
              textAlign: 'center',
              marginBottom: '2px',
            }}
          >
            Don't have account,{' '}
            <a
              href={`/sign-up${query}&type=SIGNUP`}
              style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
            >
              Sign Up
            </a>
            now
          </Typography>
        )}
      </Box>
    </>
  );
};
